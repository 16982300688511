import { EditorialPortableText } from './EditorialPortableText'
import { PortableTextBlocks } from '@liftfoils/models'
import { styled } from '@liftfoils/styles'
import { useState } from 'react'

const Wrap = styled('div', {
  width: '100%',
  maxWidth: '900px',
  margin: '0 auto',
  padding: '$8 0',

  variants: {
    theme: {
      light: {
        borderTop: '1px solid $gray500_01',

        '&:last-child': {
          borderBottom: '1px solid $gray500_01',
        },
      },
      dark: {
        borderTop: '1px solid $gray500_08',

        '&:last-child': {
          borderBottom: '1px solid $gray500_08',
        },
      },
    },
  },
})

const TitleWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  gap: '$6',
  alignItems: 'center',
})

const Title = styled('h4', {
  lift_font: 'heading03',
  textAlign: 'left',
  width: '100%',

  variants: {
    theme: {
      light: {
        color: '$gray500',
      },
      dark: {
        color: '$gray200',
      },
    },
  },
})

const EditorialBlockWrap = styled('div', {
  lift_font: 'body02',
  color: '$gray300',
  transition: 'height 0.3s ease, padding-top 0.3s ease',
  variants: {
    isOpen: {
      true: {
        height: 'auto',
        paddingTop: '$8',
      },
      false: {
        height: '0',
        overflow: 'hidden',
        paddingTop: '0',
      },
    },
  },
})

const ToggleWrap = styled('div', {
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'end',
})

const ToggleDiv = styled('div', {
  position: 'relative',
  width: '20px',
  height: '20px',
  cursor: 'pointer',
  border: '1px solid #C2C2C2',
  borderRadius: '50%',
  padding: '4px',
  alignSelf: 'center',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    width: '8px',
    height: '1px',
    backgroundColor: '#C2C2C2',
    transition: 'transform 0.3s ease',
  },
  '&::before': {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  '&::after': {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) rotate(90deg)',
  },
  variants: {
    isOpen: {
      true: {
        border: '1px solid $gray500',
        backgroundColor: '$gray500',
        '&::before': {
          transform: 'translate(-50%, -50%)',
          background: '$white',
        },
        '&::after': {
          transform: 'translate(-50%, -50%) rotate(180deg)',
          background: '$white',
        },
      },
      false: {},
    },
  },
})

export type SectionFAQAccordionProps = {
  title?: string
  text?: PortableTextBlocks
  theme: 'dark' | 'light'
}

export const SectionFAQAccordion = ({
  title,
  text,
  theme,
}: SectionFAQAccordionProps) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Wrap onClick={() => setIsOpen(!isOpen)} theme={theme}>
      <TitleWrap>
        <Title theme={theme}>{title}</Title>
        <ToggleWrap>
          <ToggleDiv isOpen={isOpen} />
        </ToggleWrap>
      </TitleWrap>
      {text && (
        <EditorialBlockWrap isOpen={isOpen}>
          <EditorialPortableText content={text} />
        </EditorialBlockWrap>
      )}
    </Wrap>
  )
}
