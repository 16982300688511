import { FC } from 'react'
import {
  Button,
  Modal,
  Link,
  Media,
  MediaPlaceholder,
  ProductVariantPrice,
} from '@liftfoils/components'
import { breakpoints, styled, useMediaQuery } from '@liftfoils/styles'
import { useCart } from '../useCart'
import { Close } from '@liftfoils/icons'
import { useTranslation } from 'react-i18next'
import {
  ShopifyResolvedCartLine,
  ShopifyResolvedVariantBasic,
} from '@liftfoils/shopify-sdk'
import { useAccount } from '@liftfoils/services/shopify-service'

const Content = styled('div', {
  m: '$9',
  display: 'grid',
  gridGap: '$8',
  '@md': {
    minWidth: '420px',
  },
})
const Border = styled('div', {
  height: '1px',
  content: ' ',
  backgroundColor: '$gray200',
})
const ModalHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  lift_font: 'heading04',
})

const Product = styled('div', {
  display: 'grid',
  gridTemplateColumns: '100px 1fr',
  alignItems: 'start',
  gridGap: '$8',
  lift_font: 'heading04',
})
const Attribute = styled('div', {
  lift_font: 'body03',
  color: '$gray400',
})
const ProductPrice = styled('p', {
  color: '$gray500',
})
const ProductImage = styled('div', {
  border: '1px solid',
  borderColor: '$gray200',
  borderRadius: '$r2',
  overflow: 'hidden',
  height: '100%',
})

const ButtonsWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '$6',
})

type CartModalProps = {
  isOpen: boolean
  message: string
  handleClose: () => void
  newProductVariants?: ShopifyResolvedVariantBasic[]
  checkoutUrl?: string
  bundleId?: string
  attributes?: ShopifyResolvedCartLine['attributes']
}

export const CartNotificationModal: FC<CartModalProps> = ({
  isOpen,
  handleClose,
  newProductVariants,
  checkoutUrl,
  message,
}) => {
  const { quantity } = useCart()
  const matchesMd = useMediaQuery(breakpoints['md'])
  const { t } = useTranslation('cart')
  const { isB2B } = useAccount()

  if (!(newProductVariants && newProductVariants.length > 0)) return null

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      position={matchesMd ? 'top-right' : 'top'}
      size={matchesMd ? 'intrinsic' : 'stretch-x'}
      variant={matchesMd ? 'white-rounded' : 'default'}
    >
      <Content>
        <ModalHeader>
          {message}
          <Button onClick={handleClose} appearance={'flex'}>
            <Close />
          </Button>
        </ModalHeader>
        <Border />
        {newProductVariants.map((subjectVariant) => {
          const attributes =
            subjectVariant.selectedOptions.length === 1 &&
            subjectVariant.selectedOptions[0].value === 'Default'
              ? []
              : subjectVariant.selectedOptions.map((option) => ({
                  key: option.name,
                  value: option.value,
                }))
          return (
            <Product key={subjectVariant.id}>
              <MediaPlaceholder ratio={'portrait2'}>
                <ProductImage>
                  {subjectVariant.image && (
                    <Media
                      mediaPayload={subjectVariant.image}
                      sizes={'220px'}
                      layout={'fill'}
                      objectFit={'contain'}
                      alt=""
                    />
                  )}
                </ProductImage>
              </MediaPlaceholder>
              <div>
                <h2>{subjectVariant.product.title}</h2>
                {!isB2B && (
                  <ProductPrice>
                    <ProductVariantPrice price={subjectVariant.price} />
                  </ProductPrice>
                )}
                {attributes
                  .filter(
                    (a) => !(a.key === 'Title' && a.value === 'Default Title'),
                  )
                  .map((a) => (
                    <Attribute key={a.key}>
                      {a.key}: {a.value}
                    </Attribute>
                  ))}
              </div>
            </Product>
          )
        })}

        <Border />
        <ButtonsWrap>
          <Link appearance="solidGray500" size="large" href="/cart">
            {t('viewCart')} ({quantity})
          </Link>
          {checkoutUrl && (
            <Link appearance="solidTeal500" size="large" href={checkoutUrl}>
              {t('checkout')}
            </Link>
          )}
        </ButtonsWrap>
      </Content>
    </Modal>
  )
}
