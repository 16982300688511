import { DEFAULT_LOCALE_ID, LocaleId } from '@liftfoils/configs'
import { groq } from 'next-sanity'
import { createPortableTextProjection } from './createPortableTextProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createMediaProjection } from './createMediaProjection'

export const createEditorialPortableTextProjection = (
  localeId: LocaleId,
  defaultLocaleId = DEFAULT_LOCALE_ID,
) => {
  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(
    localeId,
    defaultLocaleId,
  )
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  return groq`{
    ...${PORTABLE_TEXT_PROJECTION},
    _type == "standardImage" => {
      _type,
      _key,
      media${MEDIA_PROJECTION},
      ${coalesceLocaleField(
        'caption',
        localeId,
        defaultLocaleId,
      )}[]${PORTABLE_TEXT_PROJECTION}
    },
    _type == "wideImage" => {
      _type,
      _key,
      media${MEDIA_PROJECTION},
      ${coalesceLocaleField(
        'caption',
        localeId,
        defaultLocaleId,
      )}[]${PORTABLE_TEXT_PROJECTION}
    },
    _type == "twoImages" => {
      _type,
      _key,
      firstMedia{
        ${coalesceLocaleField(
          'caption',
          localeId,
          defaultLocaleId,
        )}[]${PORTABLE_TEXT_PROJECTION},
        media${MEDIA_PROJECTION}
      },
      secondMedia{
        ${coalesceLocaleField(
          'caption',
          localeId,
          defaultLocaleId,
        )}[]${PORTABLE_TEXT_PROJECTION},
        media${MEDIA_PROJECTION}
      }
    },
    _type == "video" => {
      _type,
      _key,
      "url": coalesce(${localeId}, ${DEFAULT_LOCALE_ID}).url
    },
    _type == "instagramEmbed" => {
      _type,
      _key,
      embedCode
    },
    _type == "sectionShopTheStory" => {
      _type,
      _key,
      product->{
        _type == "efoil" => {
           "id": mainProduct -> store.gid,
        },
        _type == "product" => {
           "id":  store.gid,
        },
      },
     },
     _type == "sectionFAQAccordion" => {
      _type,
      key,
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('text', localeId)}[]${PORTABLE_TEXT_PROJECTION},
     }
  }`
}
