import { createStitches } from '@stitches/react'
import type * as Stitches from '@stitches/react'
import { BREAKPOINTS_ARRAY, Breakpoint, breakpoints } from './breakpoints'

const lin = (
  cssProp: string,
  mobileValue: number,
  desktopValue: number,
  extraSmallDeviceValue?: number,
  floorBreakpoint: Breakpoint = BREAKPOINTS_ARRAY[0],
  ceilBreakpoint: Breakpoint = BREAKPOINTS_ARRAY[BREAKPOINTS_ARRAY.length - 1],
) => {
  return {
    [cssProp]: extraSmallDeviceValue ?? mobileValue + 'px',
    [`@${floorBreakpoint.name}`]: {
      [cssProp]: `calc(${mobileValue}px + (${desktopValue} - ${mobileValue}) * ((100vw - ${floorBreakpoint.value}px) / (${ceilBreakpoint.value} - ${floorBreakpoint.value})))`,
    },
    [`@${ceilBreakpoint.name}`]: {
      [cssProp]: desktopValue + 'px',
    },
  }
}

// const rs = (prop, values) => {
//   const cssObj = {}
//   for (const [breakpoint, value] of Object.entries(values)) {
//     if (breakpoint === 'default') {
//       cssObj[prop] = value
//     } else {
//       const key = `@media (min-width: ${SCREENS[breakpoint]})`
//       cssObj[key] = {}
//       cssObj[key][prop] = value
//     }
//   }
//   return cssObj
// }

type Size = {
  name?: string
  value: number | string | [number, number]
}

const SIZES: Size[] = [
  { value: 0 }, // $0 ... $16
  { value: 1 },
  { value: 2 },
  { value: 4 },
  { value: 6 },
  { value: 8 },
  { value: 12 },
  { value: 16 },
  { value: 24 },
  { value: 32 },
  { value: 48 },
  { value: 64 },
  { value: 96 },
  { value: 128 },
  { value: 160 },
  { value: 220 },
  { value: 280 },
  { name: 'XS', value: [8, 12] }, // responsive value
  { name: 'S', value: [16, 24] },
  { name: 'M', value: [32, 48] },
  { name: 'L', value: [48, 64] },
  { name: 'XL', value: [64, 96] },
  { name: 'XXL', value: [96, 128] },
  { name: 'headerHeight', value: 76 },
  { name: 'buttonHeightS', value: 34 },
  { name: 'buttonHeightM', value: 45 },
  { name: 'buttonHeightL', value: 55 },
  { name: 'containerMargin', value: '5vw' },
  { name: 'maxWidthXL', value: '1440px' },
]
const sizes: { [key: string]: string } = {}
SIZES.forEach((s, i) => {
  const key = s.name ?? i.toString()
  switch (typeof s.value) {
    case 'number':
      sizes[key] = `${s.value.toString()}px`
      break
    case 'string':
      sizes[key] = s.value
      break
    case 'object': // don't add responsive sizes to stitches variables
      break
  }
})

const transformSize = (cssProp: string | string[], name: string) => {
  const size = SIZES.find((s: Size) => s.name === name)
  if (typeof cssProp === 'string') {
    if (size && Array.isArray(size.value)) {
      const mobileValue = size.value[0]
      const desktopValue = size.value[1]
      return {
        ...lin(cssProp, mobileValue, desktopValue),
      }
    } else {
      return { cssProp: name }
    }
  } else {
    if (size && Array.isArray(size.value)) {
      const output: any = {}
      const mobileValue = size.value[0]
      const desktopValue = size.value[1]

      cssProp.forEach((c) => {
        output[c] = null
      })
      cssProp
        .map((c) => lin(c, mobileValue, desktopValue))
        .forEach((item) => {
          Object.keys(item).forEach((key) => {
            const itemElement = item[key]
            if (
              output[key] &&
              key[0] === '@' &&
              typeof itemElement === 'object'
            ) {
              output[key] = {
                ...output[key],
                ...itemElement,
              }
            } else {
              output[key] = itemElement
            }
          })
        })
      return output
    } else {
      const obj: { [key: string]: string } = {}
      cssProp.forEach((c) => {
        obj[c] = name
      })
      return obj
    }
  }
}

const FONT_LIGHT = {
  fontFamily: 'soehne-web-leicht, sans-serif',
  fontWeight: 300,
}
const FONT_REGULAR = {
  fontFamily: 'soehne-web-buch, sans-serif',
  fontWeight: 400,
}
const FONT_BOLD = {
  fontFamily: 'soehne-web-dreiviertelfett, sans-serif',
  fontWeight: 700,
}
export const LIFT_FONTS: { [key: string]: any } = {
  caps01: {
    ...lin('fontSize', 52, 120, 44),
    lineHeight: 1,
    textTransform: 'uppercase',
    ...FONT_BOLD,
    // wordBreak: 'break-word',
  },
  caps02: {
    ...lin('fontSize', 50, 70, 42),
    lineHeight: 1,
    textTransform: 'uppercase',
    ...FONT_BOLD,
    wordBreak: 'break-word',
  },
  caps03: {
    ...lin('fontSize', 22, 50),
    lineHeight: 1.1,
    textTransform: 'uppercase',
    ...FONT_BOLD,
  },
  caps04: {
    ...lin('fontSize', 16, 18),
    lineHeight: 1.2,
    textTransform: 'uppercase',
    ...FONT_LIGHT,
  },
  caps05: {
    ...lin('fontSize', 20, 26),
    lineHeight: 1.2,
    textTransform: 'uppercase',
    ...FONT_LIGHT,
  },
  heading01: {
    ...lin('fontSize', 28, 36),
    lineHeight: 1.15,
    ...FONT_LIGHT,
  },
  heading02: {
    ...lin('fontSize', 22, 26),
    lineHeight: 1.3,
    ...FONT_LIGHT,
  },
  heading03: {
    fontSize: 19, // 19
    lineHeight: 1.5,
    ...FONT_REGULAR,
  },
  heading04: {
    fontSize: 19, // 19
    lineHeight: 1.5,
    ...FONT_LIGHT,
  },
  heading05: {
    ...lin('fontSize', 16, 26),
    lineHeight: 1.2,
    ...FONT_LIGHT,
  },
  body01: {
    ...lin('fontSize', 16, 26),
    lineHeight: 1.5,
    ...FONT_REGULAR,
  },
  body02: {
    fontSize: 16,
    lineHeight: 1.4,
    ...FONT_REGULAR,
  },
  body03: {
    fontSize: 13, // lh 23px,
    lineHeight: 1.4,
    letterSpacing: '0.007em',
    ...FONT_REGULAR,
  },
  body04: {
    fontSize: 13, // lh 23px,
    letterSpacing: '0.007em',
    lineHeight: 1.4,
    ...FONT_LIGHT,
  },
  body05: {
    fontSize: 18,
    lineHeight: 1.28,
    ...FONT_REGULAR,
  },
  body06: {
    fontSize: 12,
    lineHeight: 1.67,
    ...FONT_REGULAR,
  },
} as const
export const LIFT_FONTS_NAMES = [
  'caps01',
  'caps02',
  'caps03',
  'caps04',
  'caps05',
  'heading01',
  'heading02',
  'heading03',
  'heading04',
  'heading05',
  'body01',
  'body02',
  'body03',
  'body04',
  'body05',
  'body06',
] as const
type FontStyles = (typeof LIFT_FONTS_NAMES)[number]

const stitches = createStitches({
  theme: {
    // convention for color naming is tailwind-like with a _ suffix describing opacity
    colors: {
      lightGray: 'rgba(216, 216, 216, 0.15)',
      gray100: '#f5f5f5', // derivative of gray500_01 on white bg
      gray100_05: 'rgba(235, 235, 235, 0.5)',
      gray200: '#dadada', // derivative of gray500_02 on white bg
      gray200_08: 'rgba(218,218,218,0.8)', // derivative of gray500_02 on white bg
      gray250: '#d8d8d8', // derivative of gray500_01 on white bg
      gray250_01: 'rgba(216,216,216,0.1)',
      gray300: '#919090', // derivative of gray500_06 on white bg
      gray300_03: 'rgba(145,144,144, 0.3)', // derivative of gray500_06 on white bg
      gray300_08: 'rgba(145,144,144, 0.8)', // derivative of gray500_06 on white bg
      gray400: '#6c6b6b', // derivative of gray500_08 on white bg
      gray500: '#474646', // the base gray in the project
      gray500_01: 'rgba(71, 70, 70, 0.1)',
      gray500_02: 'rgba(71, 70, 70, 0.2)',
      gray500_06: 'rgba(71, 70, 70, 0.6)',
      gray500_08: 'rgba(71, 70, 70, 0.8)',
      navy800: '#2E3335',
      navy800_02: 'rgba(46, 51, 53, 0.2)',
      red500: '#E65E25',
      teal500: '#36B7B2', // the base teal in the project
      teal500_05: 'rgba(54,183,178,0.5)', // the base teal in the project
      teal600: '#19A8A2',
      white_01: 'rgba(255, 255, 255, 0.1)',
      white_02: 'rgba(255, 255, 255, 0.2)',
      white_04: 'rgba(255, 255, 255, 0.4)',
      white_06: 'rgba(255, 255, 255, 0.6)',
      white_08: 'rgba(255, 255, 255, 0.8)',
      white: '#FFFFFF',
      black: '#000000',
    },
    radii: {
      r1: '16px',
      r2: '6px',
      r3: '22px',
      rMax: '9999px',
    },
    space: sizes,
    sizes: sizes,
  },
  media: breakpoints,
  utils: {
    // Abbreviated margin properties
    m: (value: string) => ({ margin: value }),
    mt: (value: string) => ({ marginTop: value }),
    mr: (value: string) => ({ marginRight: value }),
    mb: (value: string) => ({ marginBottom: value }),
    ml: (value: string) => ({ marginLeft: value }),
    mx: (value: string) => ({ marginLeft: value, marginRight: value }),
    my: (value: string) => ({ marginTop: value, marginBottom: value }),
    // Abbreviated padding properties
    p: (value: string) => ({ padding: value }),
    pt: (value: string) => ({ paddingTop: value }),
    pr: (value: string) => ({ paddingRight: value }),
    pb: (value: string) => ({ paddingBottom: value }),
    pl: (value: string) => ({ paddingLeft: value }),
    px: (value: string) => ({ paddingLeft: value, paddingRight: value }),
    py: (value: string) => ({ paddingTop: value, paddingBottom: value }),
    bg: (value: string) => ({
      background: value,
    }),
    lift_font: (value: FontStyles) => LIFT_FONTS[value],
    // Abbreviated margin properties
    $m: (value: string) => transformSize('margin', value),
    $mt: (value: string) => transformSize('marginTop', value),
    $mr: (value: string) => transformSize('marginRight', value),
    $mb: (value: string) => transformSize('marginBottom', value),
    $ml: (value: string) => transformSize('marginLeft', value),
    $mx: (value: string) => transformSize(['marginLeft', 'marginRight'], value),
    $my: (value: string) => transformSize(['marginTop', 'marginBottom'], value),
    // Abbreviated padding properties
    $p: (value: string) => transformSize('padding', value),
    $pt: (value: string) => transformSize('paddingTop', value),
    $pr: (value: string) => transformSize('paddingRight', value),
    $pb: (value: string) => transformSize('paddingBottom', value),
    $pl: (value: string) => transformSize('paddingLeft', value),
    $px: (value: string) =>
      transformSize(['paddingLeft', 'paddingRight'], value),
    $py: (value: string) =>
      transformSize(['paddingTop', 'paddingBottom'], value),
    $posterHeight: () => {
      return {
        minHeight: '85vh',
        '@lg': {
          minHeight: '95vh',
        },
      }
    },
    $hasContainerMX: () => {
      return {
        mx: '$containerMargin',
      }
    },
    // Shadow utils
    $outlineFocusInputShadow: (color: string) => ({
      boxShadow: `0 0 0 2px ${color}`,
    }),
  },
})
export type CSS = Stitches.CSS<typeof stitches.config>

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = stitches
