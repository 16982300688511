import {
  ShopifyProductBasicFieldsFragment,
  ShopifyProductExtendedFieldsFragment,
} from '../generated/schemaSdk'

/**
 * Add new product metafield to this list
 */
const PRODUCT_METAFIELDS_KEYS = [
  // One String Value Metafields
  'rating',
  'ratingCount',
  'additional_message',
  // List of Boolean values metafields
  'show_additional_message',
  'exclude_from_search',
  'exclude_from_retail',
  'exclude_from_indexing',
  'show_variant_image_as_option_image',
  // List of String values metafields
  //'complementary_products',
] as const

/**
 * Add metafield type to hit type
 */
type ResolvedProductMetafields = Partial<{
  rating: number | null
  ratingCount: number
  show_additional_message: boolean
  additional_message: string
  exclude_from_search: boolean
  exclude_from_retail: boolean
  exclude_from_indexing: boolean
  show_variant_image_as_option_image: boolean
}>

type ProductMetafieldsKeysType = (typeof PRODUCT_METAFIELDS_KEYS)[number]

/**
 * Add metafield to this list if they are type of List of String values
 */
const listOfTextMetafields: ProductMetafieldsKeysType[] = [
  //'complementary_products',
]

/**
 * Add metafield to this list if they are type of Boolean values
 */
const listOfBooleanMetafields: ProductMetafieldsKeysType[] = [
  'show_additional_message',
]

const isInArray = <T, A extends T>(
  item: T,
  array: ReadonlyArray<A>,
): item is A => array.includes(item as A)

const isMetafieldKey = (v: string): v is ProductMetafieldsKeysType => {
  return isInArray(v, PRODUCT_METAFIELDS_KEYS)
}

export const formatProductMetafields = (
  metafields:
    | ShopifyProductBasicFieldsFragment['metafields']
    | ShopifyProductExtendedFieldsFragment['metafields'],
): ResolvedProductMetafields => {
  return metafields.reduce<ResolvedProductMetafields>((acc, metafield) => {
    if (!metafield) return acc

    const { value, key: metafieldName } = metafield

    if (!isMetafieldKey(metafieldName)) {
      return acc
    }

    const parsedValue = (() => {
      if (!value) {
        return []
      }
      return value.includes('[') ? JSON.parse(value) : [value]
    })()

    /**
     * Text Values parser
     */
    const parsedTextValues = (() => {
      if (Array.isArray(parsedValue)) {
        return parsedValue.filter((i) => typeof i === 'string')
      }
      return []
    })()

    // List of text metafields
    if (listOfTextMetafields.includes(metafieldName)) {
      return {
        ...acc,
        [metafieldName]: parsedTextValues,
      }
    }

    // List of Boolean metafields
    if (listOfBooleanMetafields.includes(metafieldName)) {
      return {
        ...acc,
        [metafieldName]: JSON.parse(parsedValue),
      }
    }

    // Single string metafields
    return {
      ...acc,
      [metafieldName]: parsedTextValues[0],
    }
  }, {})
}
