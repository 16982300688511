import { ValidateFnType } from '../models/ValidateFnType'

const EMAIL_REGEXP = /(.+)@(.+){2,}\.(.+){2,}/

export const validateEmail: ValidateFnType = (t) => ({
  pattern: {
    value: EMAIL_REGEXP,
    message: t('validate.invalidEmailError'),
  },
})
