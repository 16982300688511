import { createMediaProjection } from './projections/createMediaProjection'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { PageBuilderSection, PageResponse } from '@liftfoils/models'
import { createPageBuilderProjection } from './projections/createPageBuilderProjection'
import { DEFAULT_LOCALE_ID, LocaleId } from '@liftfoils/configs'
import { coalesceLocaleField, createBaseImageProjection } from '..'
import { createPortableTextProjection } from './projections/createPortableTextProjection'
import { createProductPageConfigProjection } from './projections/createProductPageConfigProjection'

export const createProductPageProjection = (localeId: LocaleId) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const PORTABLE_TEXT_PROJECTION = createPortableTextProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const BASE_IMAGE_PROJECTION = createBaseImageProjection(localeId)

  const PRODUCT_PAGE_CONFIG_PROJECTION =
    createProductPageConfigProjection(localeId)
  return groq`{
    "initialHeaderStyle": 'transparent-black',
    "showFooter": true,
    title,
    "handle": slug.current,
    "productId": gid,
    "pageBuilder": [
      {
        "_type": "sectionProductConversionArea",
        "_key": "sectionProductConversionArea" + _id,
        "priority": true,
         importantFeatures{
            ${coalesceLocaleField('key', localeId)},
            ${coalesceLocaleField('value', localeId)},
        }[],
        enrichedProductOptions{
          _key,
          shopifyReference,
          showGuidanceModal,
          ${coalesceLocaleField('guidanceModalLabel', localeId)},
          wideElements,
          expandAllValues,
          values{
            _key,
            title,
            image${BASE_IMAGE_PROJECTION},
            ${coalesceLocaleField(
              'additionalDescription',
              localeId,
            )}[]${PORTABLE_TEXT_PROJECTION},
            "features": features{
              "val": coalesce(${localeId}, ${DEFAULT_LOCALE_ID})
            }[].val
          }[]
        }[],
        "gearSelectorType" : gearSelectorReference -> gearSelectorType,
        ${coalesceLocaleField(
          'additionalDescription',
          localeId,
        )}[]${PORTABLE_TEXT_PROJECTION},
        video{
          ${coalesceLocaleField('title', localeId)},
          thumbnail${MEDIA_PROJECTION},
          youtubeVideoUrl
        },
        "productPageConfig":${PRODUCT_PAGE_CONFIG_PROJECTION},
        "shopifyIds": {
          "productBasic": [...additionalProducts[].product -> store.gid]
        },
        "additionalProducts": additionalProducts[]{
          "id": product -> store.gid,
          ${coalesceLocaleField('description', localeId)}
        },
        ${coalesceLocaleField('additionalProductsLabel', localeId)},
      },
      ...pageBuilder[]{
        "x": {
          ...,
        }
      }.x{
        b2bOnly,
        ...${PAGE_BUILDER_PROJECTION}
      },
    ],
  }`
}

export const getProductPageByHandle = async (
  handle: string,
  locale: string,
  preview = false,
): Promise<PageResponse<PageBuilderSection[]>> => {
  const [, localeId] = getLocaleRegionIdFromPath(locale)
  const vendor = 'Lift Foils'
  const PRODUCT_PROJECTION = createProductPageProjection(localeId)

  const draftFilter = preview ? '' : `!(_id in path('drafts.**')) && `
  const query = groq`*[${draftFilter} _type == "product" && store.slug.current == $handle && store.isDeleted == false && store.status == "active" && store.vendor==$vendor][0]${PRODUCT_PROJECTION}`

  return await getClient(preview).fetch(query, { handle, vendor })
}
