import { PortableTextBlocks } from '@liftfoils/models'
import { getYouTubeIdFromUrl } from '@liftfoils/utils'
import { FC } from 'react'
import { PortableText } from '../PortableText/PortableText'
import { YouTubePlayer } from '../YouTubePlayer/YouTubePlayer'
import { InstagramEmbed } from './InstagramEmbed'
import { StandardImage } from './StandardImage'
import { TwoImages } from './TwoImages'
import { SectionShopTheStory } from './SectionShopTheStory'
import { ShopifyData } from '@liftfoils/shopify-sdk'
import { SectionFAQAccordion } from './SectionFAQAccordion'

export const EditorialPortableText: FC<{
  content?: PortableTextBlocks
  shopifyData?: ShopifyData
  theme?: 'dark' | 'light'
}> = ({ content, shopifyData, theme = 'light' }) => {
  return (
    <PortableText
      value={content}
      additionalComponents={{
        types: {
          standardImage: (props) => <StandardImage {...props.value} />,
          wideImage: (props) => <StandardImage {...props.value} />,
          twoImages: (props) => <TwoImages {...props.value} />,
          video: (props) => (
            <div className="youtubePlayer">
              <YouTubePlayer
                videoId={getYouTubeIdFromUrl(props.value.url)}
                autoplay={false}
              />
            </div>
          ),
          instagramEmbed: (props) => <InstagramEmbed {...props.value} />,
          sectionShopTheStory: (props) => (
            <SectionShopTheStory {...props.value} shopifyData={shopifyData} />
          ),
          sectionFAQAccordion: (props) => (
            <SectionFAQAccordion {...props.value} theme={theme} />
          ),
        },
      }}
    />
  )
}
