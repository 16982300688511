import { FC, useEffect, useState } from 'react'
import { Button, Loader, ProductVariantPrice } from '@liftfoils/components'
import { styled } from '@liftfoils/styles'
import { useRouter } from 'next/router'
import { useCart } from '../useCart'
import {
  getBundleAdditionalCartLinesByBundleId,
  getBundleBaseCartLineByBundleId,
  getBundleId,
  getNodesWithBuyerContext,
  getVariantsSumPrice,
  useAccount,
} from '@liftfoils/services/shopify-service'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import {
  BUNDLE_ID_ADDITIONAL_PREFIX,
  BUNDLE_ID_BASE_PREFIX,
  BUNDLE_ID_KEY,
  BUNDLE_QUERY_EDIT_KEY,
} from '@liftfoils/configs'
import { CartNotificationModal } from '../AddToCartButton/CartNotificationModal'
import { GTMAddManyToCart } from '@liftfoils/services/gtm-service'
import { ShopifyResolvedVariantBasic } from '@liftfoils/shopify-sdk'
const ErrorMessage = styled('p', {
  lift_font: 'body06',
  color: '$red500',
  textAlign: 'right',
  mt: '$4',
})
const ButtonWrap = styled('div', {
  display: 'grid',
  color: 'white',
})

type AddBundleToCartButtonProps = {
  baseProductVariant?: ShopifyResolvedVariantBasic
  additionalProductVariants?: ShopifyResolvedVariantBasic[]
  attributes?: { key: string; value: string }[]
}

export const AddBundleToCartButton: FC<AddBundleToCartButtonProps> = ({
  baseProductVariant,
  additionalProductVariants,
  attributes,
}) => {
  const router = useRouter()
  const { locale, query, replace, pathname } = router

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorNotification, setErrorNotification] = useState<string | null>(
    null,
  )
  const [cartModalOpen, setCartModalOpen] = useState<boolean>(false)
  const { cart, addCartLines, removeCartLines, processing } = useCart()
  const [incomingVariants, setIncomingVariants] = useState<
    Array<ShopifyResolvedVariantBasic> | undefined
  >(undefined)

  const { t } = useTranslation('cart')

  const { account } = useAccount()

  const { companyLocationId, customerAccessToken } = account ?? {}

  const buyer =
    customerAccessToken && companyLocationId
      ? { customerAccessToken, companyLocationId }
      : undefined

  const [generatedBundleId, setGeneratedBundleId] = useState<
    string | undefined
  >(undefined)
  const editedBundleId = query[BUNDLE_QUERY_EDIT_KEY]

  const baseVariantId = baseProductVariant?.id
  const additionalVariantIds = additionalProductVariants?.map((v) => v.id)
  const allVariantIds =
    baseVariantId && additionalVariantIds
      ? [baseVariantId, ...additionalVariantIds]
      : undefined

  const isReadyToAdd = baseVariantId && additionalVariantIds

  const createErrorNotification = (errorNotification: string) => {
    setErrorNotification(errorNotification)
    setTimeout(() => {
      setErrorNotification(null)
    }, 10000)
  }

  const handleCloseModal = () => {
    setCartModalOpen(false)
  }
  const handleClick = async () => {
    if (!isReadyToAdd) return

    setIsLoading(true)
    if (editedBundleId) {
      const lineIds = cart?.lines
        ?.filter((l) => getBundleId(l) === editedBundleId)
        .map((l) => l.id)
      if (lineIds?.length) {
        await removeCartLines(lineIds)
        delete query[BUNDLE_QUERY_EDIT_KEY]
        replace(
          {
            pathname,
            query,
          },
          undefined,
          { shallow: true },
        )
      }
    }
    const uuid = uuidv4()
    setGeneratedBundleId(uuid)

    const addedCartLines = await addCartLines([
      {
        merchandiseId: baseVariantId,
        quantity: 1,
        attributes: [
          { key: BUNDLE_ID_KEY, value: `${BUNDLE_ID_BASE_PREFIX}${uuid}` },
          ...(attributes ?? []),
        ],
      },
      ...additionalVariantIds.map((merchandiseId) => ({
        merchandiseId,
        quantity: 1,
        attributes: [
          {
            key: BUNDLE_ID_KEY,
            value: `${BUNDLE_ID_ADDITIONAL_PREFIX}${uuid}`,
          },
        ],
      })),
    ])
    setIsLoading(false)
    if (!addedCartLines) {
      createErrorNotification(t('tryAgain'))
      return
    }
    const addedBundleBase = getBundleBaseCartLineByBundleId(
      addedCartLines,
      uuid,
    )
    const addedBundleAdditional = getBundleAdditionalCartLinesByBundleId(
      addedCartLines,
      uuid,
    )
    if (!(addedBundleBase && addedBundleAdditional.length)) {
      createErrorNotification(t('tryAgain'))
      return
    }
    setCartModalOpen(true)

    GTMAddManyToCart([addedBundleBase, ...addedBundleAdditional])

    setTimeout(() => {
      handleCloseModal()
    }, 6000)
  }

  const refreshAvailability = async () => {
    if (!(allVariantIds && locale)) return
    setIsLoading(true)

    const productVariants = await getNodesWithBuyerContext(
      allVariantIds,
      locale,
      buyer,
    ).then(
      (nodes) =>
        nodes.filter(
          (node) => node.__typename === 'ResolvedVariantBasic',
        ) as ShopifyResolvedVariantBasic[],
    )
    setIsLoading(false)
    setIncomingVariants(productVariants)
  }
  useEffect(() => {
    refreshAvailability()
    window.addEventListener('focus', refreshAvailability)
    return () => window.removeEventListener('focus', refreshAvailability)
  }, [locale, JSON.stringify(buyer)])

  const stringBundleVariantIds = JSON.stringify(allVariantIds)
  useEffect(() => {
    refreshAvailability()
  }, [stringBundleVariantIds]) // We don't want to fire refreshAvailability when values of array (not references) didn't change.

  const computedNotAvailable =
    incomingVariants?.length !== allVariantIds?.length ||
    !incomingVariants?.every((v) => v?.availableForSale)
  const computedLoading = (cart && processing) || isLoading
  const computedDisabled = isLoading || computedNotAvailable
  const computedPrice = getVariantsSumPrice(incomingVariants)

  const buttonText = (() => {
    if (isLoading) return t('productUnavailable')
    if (editedBundleId) return t('saveConfiguration')
    if (!allVariantIds) return 'Select all options'
    if (computedDisabled) return t('productUnavailable')
    return t('addToCart')
  })()
  return (
    <>
      <ButtonWrap>
        <Button
          appearance={'solidTeal500'}
          size={'large'}
          disabled={computedDisabled || computedLoading}
          onClick={handleClick}
        >
          {computedLoading ? (
            <Loader />
          ) : (
            <>
              {incomingVariants && incomingVariants.length > 0 && (
                <>
                  <ProductVariantPrice price={computedPrice} />
                  <span>·</span>
                </>
              )}
              {buttonText}
            </>
          )}
        </Button>
        {errorNotification && <ErrorMessage>{errorNotification}</ErrorMessage>}
      </ButtonWrap>
      {generatedBundleId && baseProductVariant && (
        <CartNotificationModal
          message={
            editedBundleId ? t('configurationEditSuccess') : t('addedToCart')
          }
          isOpen={cartModalOpen}
          handleClose={handleCloseModal}
          checkoutUrl={cart?.checkoutUrl}
          newProductVariants={[
            {
              ...baseProductVariant,
              selectedOptions:
                attributes
                  ?.filter((a) => !a.key.startsWith('_'))
                  .map((a) => ({
                    name: a.key,
                    value: a.value,
                  })) || [],
              price: computedPrice ?? baseProductVariant.price,
            },
          ]}
        />
      )}
    </>
  )
}
