import { useEffect } from 'react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { I18nextProvider } from 'react-i18next'
import { setAppElement } from 'react-modal'
import { useRouter } from 'next/router'

import { Footer, ModalNewsletter, seoImageLoader } from '@liftfoils/components'
import { Header, PROMO_BAR_HEIGHT } from '@liftfoils/header'

import getI18n from '../translations/i18n'
import { PageBuilderSection, PageResponse } from '@liftfoils/models'
import { CartProvider } from '@liftfoils/cart'
import { DefaultSeo } from 'next-seo'
import { getLocaleRegionIdFromPath } from '@liftfoils/utils'
import Script from 'next/script'
import { GTMOriginalLocation } from '@liftfoils/services/gtm-service'
import { FacebookPixelService } from '@liftfoils/services/facebook-pixel-service'
import { AssistanceProvider } from '@liftfoils/assistance'
import { GearSelectorContextProvider } from '@liftfoils/gear-selector-provider'
import { CustomerAccountProvider } from '@liftfoils/services/shopify-service'
import { styled } from '@liftfoils/styles'

type ProjectAppProps = AppProps<PageResponse<PageBuilderSection[]>>

const Root = styled('div', {
  minHeight: '100vh',
})
const MyApp = ({ Component, pageProps }: ProjectAppProps) => {
  const { locale } = useRouter()
  if (!locale) throw new Error('Locale has not been set')

  useEffect(() => {
    setAppElement('#modalContainer')
  }, [])

  useEffect(() => {
    GTMOriginalLocation()
  }, [])

  const [, localeId] = getLocaleRegionIdFromPath(locale)
  return (
    <I18nextProvider i18n={getI18n(localeId)}>
      <style jsx global>
        {`
          :root {
            --sizes-promoBarHeight: ${pageProps?.siteConfiguration?.header
              ?.promoBar?.isVisible
              ? PROMO_BAR_HEIGHT
              : 0}px;
          }
        `}
      </style>
      <GearSelectorContextProvider>
        <CustomerAccountProvider>
          <CartProvider>
            <AssistanceProvider>
              <Head>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1"
                />
                <link
                  rel="apple-touch-icon"
                  sizes="180x180"
                  href="/apple-touch-icon.png"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href="/favicon-32x32.png"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href="/favicon-16x16.png"
                />
                <link rel="manifest" href="/site.webmanifest" />
                <link
                  rel="mask-icon"
                  href="/safari-pinned-tab.svg"
                  color="#5bbad5"
                />
                <meta name="msapplication-TileColor" content="#00aba9" />
                <meta name="theme-color" content="#ffffff" />
              </Head>

              {pageProps.siteConfiguration &&
                (() => {
                  const { seo } = pageProps.siteConfiguration
                  const seoImage = seoImageLoader(seo.ogImage, seo.ogAltImage)

                  return (
                    <DefaultSeo
                      title={seo.metaTitle}
                      description={seo.metaDescription}
                      openGraph={{
                        title: seo.ogTitle,
                        description: seo.ogDescription,
                        images: [...(seoImage ? [seoImage] : [])],
                      }}
                      twitter={{
                        cardType: 'summary_large_image',
                      }}
                    />
                  )
                })()}
              {pageProps.siteConfiguration && (
                <Header
                  header={pageProps.siteConfiguration.header}
                  assistance={pageProps.siteConfiguration.assistance}
                  initialTheme={pageProps.initialHeaderStyle}
                  searchConfig={pageProps.siteConfiguration.search}
                  promoBarConfig={pageProps.siteConfiguration.header?.promoBar}
                />
              )}

              <Root>
                <Component {...pageProps} />
              </Root>
              {pageProps.siteConfiguration && pageProps.showFooter && (
                <Footer
                  footer={pageProps.siteConfiguration.footer}
                  newsletter={pageProps.siteConfiguration.newsletter}
                />
              )}
              {pageProps.siteConfiguration && (
                <ModalNewsletter
                  newsletter={pageProps.siteConfiguration.newsletter}
                />
              )}
              <div id={'modalContainer'} />
            </AssistanceProvider>
          </CartProvider>
        </CustomerAccountProvider>
      </GearSelectorContextProvider>

      <FacebookPixelService id={'594233667711765'} />

      <Script
        strategy="afterInteractive"
        id="gtm"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5J6ZHSC');
          `,
        }}
      />
    </I18nextProvider>
  )
}
export default MyApp
